import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';

import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
import { ValidationObserver } from 'vee-validate';
import ErrorMonitoring from 'Utils/error-monitoring';
import Tracker from 'Utils/tracker';
import GlobalToast from 'Plugins/global-toast';

import App from './App.vue';
import router from './router';
import store from './store';

import Api from './api/service';
import interceptors from './api/service/interceptors';

import '@/assets/scss/index.scss';

Vue.use(VueCompositionAPI);
Vue.use(ErrorMonitoring);
Vue.use(Tracker);
Vue.use(GlobalToast, { store });

Vue.config.productionTip = false;

Vue.prototype.$api = Api;
interceptors();

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
