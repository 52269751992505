export default [
  {
    path: '/room-allotment/table-view',
    name: 'room-allotment-table-view',
    component: () =>
      import(
        /* webpackChunkName: "property list - table view" */ '@admin/pages/RoomAllotmentTableView'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        /* Put your links here */
      ],
    },
  },
  {
    path: '/room-allotment/calendar-view',
    name: 'room-allotment-calendar-view',
    component: () => import('@admin/pages/RoomAllotmentCalendarView'),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: 'Ketersediaan Kamar',
        },
      ],
    },
  },
  {
    path: '/room-allotment/contract-detail/:contractId',
    name: 'room-allotment-contract-detail',
    component: () =>
      import(
        /* webpackChunkName: "property list - contract detail" */ '@admin/pages/RoomAllotmentContractDetail'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        /* Put your links here */
      ],
    },
  },
  {
    path: '/room-allotment/add-tenant/:propertyId/new-booking',
    name: 'room-allotment-add-tenant-new-booking',
    component: () =>
      import(
        /* webpackChunkName: "page-room-allotment-add-tenant-new-booking" */ '@admin/pages/RoomAllotmentAddTenant/pages/AddTenantNewBooking'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: '...',
        },
        {
          name: 'Tambah Penyewa',
        },
      ],
    },
  },
  {
    path: '/room-allotment/add-tenant/:propertyId/dbet',
    name: 'room-allotment-add-tenant-dbet',
    component: () =>
      import(
        /* webpackChunkName: "page-room-allotment-add-tenant-dbet" */ '@admin/pages/RoomAllotmentAddTenant/pages/AddTenantDbet'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: '...',
        },
        {
          name: 'Tambah Penyewa',
        },
      ],
    },
  },
  {
    path: '/room-allotment/add-tenant/:propertyId/reschedule',
    name: 'room-allotment-add-tenant-reschedule',
    component: () =>
      import(
        /* webpackChunkName: "page-room-allotment-add-tenant-reschedule" */ '@admin/pages/RoomAllotmentAddTenant/pages/AddTenantReschedule'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: '...',
        },
        {
          name: 'Tambah Penyewa',
        },
      ],
    },
  },
  {
    path: '/room-allotment/add-tenant/:propertyId/relocation',
    name: 'room-allotment-add-tenant-relocation',
    component: () =>
      import(
        /* webpackChunkName: "page-room-allotment-add-tenant-relocation" */ '@admin/pages/RoomAllotmentAddTenant/pages/AddTenantRelocation'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: '...',
        },
        {
          name: 'Tambah Penyewa',
        },
      ],
    },
  },
  {
    path: '/room-allotment/change-log/:propertyId',
    name: 'room-allotment-change-log',
    component: () =>
      import(
        /* webpackChunkName: "page-room-allotment-change-log" */ '@admin/pages/RoomAllotmentChangeLog'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: '...',
        },
        {
          name: 'Riwayat Perubahan Ketersediaan Kamar ',
        },
      ],
    },
  },
];
