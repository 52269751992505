import userApi from '@admin/api/endpoints/user';

export default {
  state: {
    data: {},
  },

  mutations: {
    updateProfile(state, user) {
      state.data = user || {};
    },
  },

  getters: {
    xCheckUserPermission: state => value => {
      const permissions = state.data.permission;
      if (permissions) {
        return permissions.includes(value);
      }
      return false;
    },
    isSuperAdmin(state) {
      const roles = state.data?.role || [];

      return roles.some(role => role.key === 'super-admin');
    },
  },

  actions: {
    async getProfile({ commit }) {
      const res = await userApi.getProfile();
      commit('updateProfile', res.data?.data);

      return res.data;
    },
  },
};
