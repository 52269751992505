<template>
  <div class="auth-layout">
    <mk-header />
    <div class="container">
      <mk-sidebar />
      <div class="content">
        <div v-if="breadcrumbLinks.length" class="breadcrumb-container">
          <bg-breadcrumb :links="breadcrumbLinks" />
        </div>
        <router-view :key="routeKey"></router-view>
      </div>
    </div>
    <global-toast />
  </div>
</template>

<script>
import { BgBreadcrumb } from 'bangul-vue';
import { mapState } from 'vuex';
import MkHeader from '@admin/components/organisms/MkHeader/MkHeader.vue';
import MkSidebar from '@admin/components/organisms/MkSidebar/MkSidebar.vue';
import GlobalToast from '@/components/molecules/GlobalToast';

export default {
  name: 'AuthLayout',

  components: {
    BgBreadcrumb,
    MkHeader,
    MkSidebar,
    GlobalToast,
  },

  created() {
    this.$store.dispatch('getProfile');
  },

  computed: {
    ...mapState({
      breadcrumbLinks: ({ breadcrumb }) => breadcrumb.breadcrumbItems,
    }),
    routeKey() {
      const { meta, fullPath } = this.$route;
      return meta.dontUpdateParentComponent ? '' : fullPath;
    },
  },

  beforeCreate() {
    document.body.style.cssText = '';
  },
};
</script>

<style lang="scss" scoped>
.container {
  // display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  line-height: 1.5;
}

.content {
  flex: 0 0 100%;
  padding: 92px 0 80px 80px;
}

.breadcrumb-container {
  padding-bottom: 34px;
  padding-left: 16px;
}
</style>
