export default {
	state: {
		isLoggedIn: false,
	},
	mutations: {
		authenticate(state) {
			state.isLoggedIn = true;
		},
		resetAuth(state) {
			state.isLoggedIn = false;
		},
	},
};
