<template>
	<header id="main-header" class="mk-header">
		<div class="mk-header__logo">
			<img src="@/assets/images/logo-small.svg" />
		</div>
		<div class="mk-header__menu">
			<div class="mk-header__menu-profile">
				<div
					class="mk-header__profile"
					:class="{ 'mk-header__profile--active': isProfileShown }"
					@click="toggleProfileDropdown"
				>
					<bg-text
						size="title-5"
						class="mk-header__profile-name mk-header__truncated"
					>
						{{ userData.name }}
					</bg-text>
					<bg-icon name="dropdown-down" size="sm" />
				</div>
				<mk-profile-dropdown v-if="isProfileShown" />
			</div>
		</div>
	</header>
</template>

<script>
import { BgText, BgIcon } from 'bangul-vue';
import MkProfileDropdown from '@admin/components/organisms/MkHeader/components/MkProfileDropdown';

export default {
	name: 'MkHeader',
	components: {
		BgText,
		BgIcon,
		MkProfileDropdown,
	},
	data() {
		return {
			isProfileShown: false,
		};
	},
	computed: {
		userData() {
			return this.$store.state.user.data || {};
		},
	},
	mounted() {
		document.addEventListener('click', this.handleClickOutsideProfile);
	},
	beforeDestroy() {
		document.removeEventListener('click', this.handleClickOutsideProfile);
	},
	methods: {
		toggleProfileDropdown() {
			this.isProfileShown = !this.isProfileShown;
		},
		handleClickOutsideProfile(event) {
			const profileClass = document.getElementsByClassName(
				'mk-header__menu-profile'
			)[0];
			const clickInsideProfile = profileClass.contains(event.target);
			if (!clickInsideProfile) {
				this.isProfileShown = false;
			}
		},
	},
};
</script>

<style
	lang="scss"
	src="@admin/components/organisms/MkHeader/MkHeader.scss"
	scoped
></style>
