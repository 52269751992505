// Import RoomAllotmentApi from '@admin_endpoints/room-allotment';

export const ModalState = {
	INITIAL: 'initial',
	COST_SAVING: 'cost_saving',
	COST_SAVED: 'cost_saved',
	ERROR_SAVING: 'error_saving',
};

export default {
	namespaced: true,

	state: {
		modalState: ModalState.INITIAL,
	}
}