export default [
  {
    path: 'property-detail/edit/owner-profile/:propertyId',
    name: 'edit.owner-profile',
    component: () =>
      import(
        /* webpackChunkName: "role management" */ '@admin/pages/FormOwnerProfile'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: 'Profil Pemilik',
        },
      ],
    },
  },

  {
    path: 'property-detail/edit/disbursement-information/:propertyId',
    name: 'edit.disbursement-information',
    component: () =>
      import(
        /* webpackChunkName: "role management" */ '@admin/pages/FormDisbursementInformation'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: 'Informasi Transfer Pendapatan',
        },
      ],
    },
  },

  {
    path: 'property-detail/edit/partnership-detail/:propertyId',
    name: 'edit.partnership-detail',
    component: () =>
      import(
        /* webpackChunkName: "role management" */ '@admin/pages/FormPartnershipDetail'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: 'Profil Pemilik',
        },
      ],
    },
  },

  {
    path: 'terminate-contract/:propertyId/:contractId',
    name: 'terminate-contract',
    component: () =>
      import(
        /* webpackChunkName: "role management" */ '@admin/pages/FormTerminateContract'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: 'Informasi Transfer Pendapatan',
        },
      ],
    },
  },

  {
    path: 'property-detail/edit/renew-contract/:propertyId',
    name: 'edit.renew-contract',
    component: () =>
      import(
        /* webpackChunkName: "role management" */ '@admin/pages/FormPartnershipDetail'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: 'Profil Pemilik',
        },
      ],
    },
  },

  {
    path: 'property-detail/edit/person-responsible/:propertyId',
    name: 'edit.person-responsible',
    component: () =>
      import(
        /* webpackChunkName: "edit person responsible" */
        '@admin/pages/FormPersonResponsible'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: 'Edit PIC',
        },
      ],
    },
  },

  {
    path: 'property-detail/edit/property-profile/:propertyId',
    name: 'edit.property-profile',
    component: () =>
      import(
        /* webpackChunkName: "form property profile" */ '@admin/pages/FormPropertyProfile'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: 'Edit Profil Properti',
        },
      ],
    },
  },

  {
    path: 'property-detail/edit/property-info/:propertyId/kos/:listingId',
    name: 'edit.property-info',
    component: () =>
      import(
        /* webpackChunkName: "form property info" */ '@admin/pages/FormPropertyListingInfo'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: 'Informasi Kamar',
        },
      ],
    },
  },

  {
    path: 'property-detail/edit/property-info/:propertyId/kos/:listingId/price',
    name: 'edit.property-price-list',
    component: () =>
      import(
        /* webpackChunkName: "form property price-list" */ '@admin/pages/FormPropertyPriceList'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: 'Harga Sewa Kamar',
        },
      ],
    },
  },

  {
    path: 'property-detail/edit/property-info/:propertyId/kos/:listingId/facility',
    name: 'edit.property-facility',
    component: () =>
      import(
        /* webpackChunkName: "form property price-list" */ '@admin/pages/FormPropertyFacility'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: 'Fasilitas',
        },
      ],
    },
  },

  {
    path: 'property-detail/edit/property-info/:propertyId/kos',
    name: 'edit.property-information',
    component: () =>
      import(
        /* webpackChunkName: "form property info" */ '@admin/pages/FormPropertyInformation'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...'
        },
        {
          name: 'Informasi Kamar',
        },
      ],
    },
  },

  {
    path: 'property-detail/edit/property-info/:propertyId/kos/:listingId/additional-fee',
    name: 'edit.property-additional-fee',
    component: () =>
      import(
        /* webpackChunkName: "form property info" */ '@admin/pages/FormPropertyAdditionalPrice'
      ),
    meta: {
      auth: true,
      breadcrumbs: [
        {
          name: 'Daftar Properti',
          url: '/',
        },
        {
          name: '...',
        },
        {
          name: 'Biaya & Layanan Tambahan',
        },
      ],
    },
  },
];
