<template>
  <div class="property-detail-layout">
    <header>
      <skelly
        width="300px"
        height="40px"
        class="mb-32"
        v-if="isLoadingPropertyDetail"
      />
      <txt size="heading-2" class="mb-32" v-else>{{
        xPropertyDetail.name
      }}</txt>
      <div class="flex align-center justify-space-between">
        <tabs v-model="activeTab" class="mb-32" @input="handleChangeTab">
          <tab
            v-for="(item, i) in tabTitles"
            :key="`tab-title-${i}`"
            :id="item.id"
            :title="item.title"
          />
        </tabs>
        <router-link
          v-if="activeTab === 1 || activeTab === 2"
          :to="contractHistoryRoute"
        >
          <bg-button-icon icon="activity">
            {{ historyButtonText }}
          </bg-button-icon>
        </router-link>
      </div>
    </header>

    <keep-alive>
      <router-view />
    </keep-alive>

    <global-toast />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { LOADING_STATE } from '@admin_store/modules/propertyDetail';

import { BgText, BgTabs, BgTab, BgSkeleton, BgButtonIcon } from 'bangul-vue';
import GlobalToast from '@/components/molecules/GlobalToast';

export default {
  name: 'PropertyDetailLayout',

  components: {
    txt: BgText,
    tabs: BgTabs,
    tab: BgTab,
    skelly: BgSkeleton,
    BgButtonIcon,
    GlobalToast,
  },

  data() {
    return {
      activeTab: 0,
      tabTitles: [
        {
          title: 'Overview',
          id: 'overview',
          url: 'property-detail.overview',
        },
        {
          title: 'Kontrak Kerja Sama',
          id: 'contract',
          url: 'property-detail.contract',
        },
        {
          title: 'Detail Kos',
          id: 'detailKos',
          url: 'property-detail.kos',
        },
        {
          title: 'Performa',
          id: 'performance',
          url: 'property-detail.performance',
        },
      ],
    };
  },

  computed: {
    ...mapState('propertyDetail', ['xLoadingState', 'xPropertyDetail']),

    historyButtonText() {
      if (this.activeTab === 1) {
        return 'Riwayat Perubahan Kontrak';
      } else if (this.activeTab === 2) {
        return 'Riwayat Perubahan Detail Kos';
      }
      return '';
    },

    isLoadingPropertyDetail() {
      return this.xLoadingState === LOADING_STATE.FETCHING;
    },

    propertyId() {
      return this.$route.params.propertyId;
    },

    contractHistoryRoute() {
      if (this.activeTab === 1) {
        return {
          name: 'property-detail.contract.history',
          params: {
            propertyId: this.propertyId,
          },
        };
      } else if (this.activeTab === 2) {
        return {
          name: 'property-detail.changelog',
          params: {
            propertyId: this.propertyId,
          },
        };
      }
      return {};
    },
  },

  watch: {
    xLoadingState: {
      immediate: true,
      handler(value) {
        if (value === LOADING_STATE.FETCH_DONE) {
          this.updateBreadcrumb({
            index: 1,
            item: {
              name: this.xPropertyDetail.name,
            },
          });
        }
      },
    },

    $route: {
      immediate: true,
      handler(value) {
        if (
          value.meta.breadcrumbs[1].name === '...' &&
          this.xLoadingState === LOADING_STATE.FETCH_DONE
        ) {
          this.updateBreadcrumb({
            index: 1,
            item: {
              name: this.xPropertyDetail.name,
            },
          });
          this.updateBreadcrumb({
            index: 2,
            item: {
              name: this.tabTitles[this.activeTab].title,
            },
          });
        }

        this.activeTab = this.tabTitles.findIndex(
          item => item.url === value.name
        );
      },
    },
  },

  created() {
    this.xConsumePropertyDetail(this.propertyId);
  },

  beforeMount() {
    const routeName = this.$route.name;
    const currentIndex = this.tabTitles.findIndex(e => e.url === routeName);
    this.activeTab = currentIndex;
    this.updateBreadcrumb({
      index: 2,
      item: {
        name: this.tabTitles[this.activeTab].title,
      },
    });
  },

  methods: {
    ...mapActions('propertyDetail', ['xConsumePropertyDetail']),
    ...mapMutations('breadcrumb', ['updateBreadcrumb']),

    handleChangeTab() {
      const selectedTabUrl = this.tabTitles[this.activeTab].url;
      if (this.$route.name !== selectedTabUrl) {
        this.$router.push({ name: selectedTabUrl });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  padding: 0 16px;
}

::v-deep .bg-c-tabs-content {
  padding: 0;
}
</style>
