export const FORMAT_DATE = 'YYYY-MM-DD';

export const timeline = {
	PAST: 'past',
	PRESENT: 'present',
	FUTURE: 'future',
};

export const asyncState = {
	LOADING: 'loading',
	LOADED: 'loaded',
	NO_DATA: 'no_data',
};
