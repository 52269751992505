import { selectedContractStatusDefault } from '@admin_molecules/HomepageFilterModal/data/contractStatusOptions';

export default {
  namespaced: true,

  state: {
    filters: {
      contract_status: [...selectedContractStatusDefault],
    },
    pagination: {
      current_page: 0,
      limit: 0,
      total_items: 1,
      offset: 0,
    },
    search: '',
    locationLists: [],
  },

  getters: {
    getQuery(state) {
      return { ...state.pagination, ...state.filters };
    },
    totalFilter(state) {
      return Object.keys(state.filters).length;
    },
    getFilter(state) {
      return (type, defaultValue = null) => state.filters[type] || defaultValue;
    },
    totalPagination(state) {
      return Math.ceil(state.pagination.total_items / state.pagination.limit);
    },
    activePagination(state) {
      return state.pagination.current_page;
    },
    getLocationList(state) {
      return state.locationLists;
    },
  },

  mutations: {
    setFilter(state, payload) {
      state.filters = { ...payload };
    },
    updateFilter(state, payload) {
      state.filters = { ...state.filters, ...payload };
    },
    setPagination(state, payload) {
      state.pagination = { ...payload };
    },
    changePagination(state, page) {
      state.pagination.offset = (page - 1) * state.pagination.limit;
    },
    resetStates(state) {
      state.filters = {
        contract_status: [...selectedContractStatusDefault],
      };
      state.pagination = {
        offset: 0,
      };
      state.search = '';
    },
    setSearch(state, payload) {
      state.search = payload;
    },

    setLocationList(state, payload) {
      state.locationLists = payload;
    },
  },

  actions: {},
};
