export default [
  {
    path: '/tenant-tracker',
    name: 'tenant-tracker',
    component: () =>
      import(/* webpackChunkName: "tenant-tracker" */ '@admin/pages/TenantTracker'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/tenant-tracker/profile/:userId/:trackerId',
    name: 'tenant-tracker.profile',
    component: () =>
      import(
        /* webpackChunkName: "tenant-tracker-profile" */ '@admin/pages/TenantTracker/components/TenantTrackerProfile'
      ),
    meta: {
      auth: true,
    },
  },
];
