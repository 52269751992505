import invoiceApi from '@admin/api/endpoints/detail-transfer.js';

export default {
  state: {
    data: {
      data: {},
    },
    approve: {},
    contract: {},
    cost: [],
    returnCost: {},
    deleteCost: {},
    editCostReturn: {},
    tenantFee: [],
    returnTenantFee: {},
    deleteTenantFee: {},
    editTenantFeeReturn: {},
  },
  mutations: {
    updateInvoiceData(state, tableData) {
      state.data = tableData;
    },
    updateApprove(state, data) {
      state.approve = data;
    },
    updateContract(state, data) {
      state.contract = data;
    },
    updateCost(state, data) {
      const tempArr = data.data.data;
      for (let i = 0; i < tempArr.length; i++) {
        const tamp = {
          amount: tempArr[i].amount ? tempArr[i].amount.toString() : '-',
          cost_id: tempArr[i].cost_id,
          name: tempArr[i].name,
          type: tempArr[i].type,
          qty: tempArr[i].qty,
          total_amount: tempArr[i].total_amount,
        };
        tempArr[i] = tamp;
      }
      state.cost = tempArr;
    },
    updateCostReturn(state, data) {
      state.returnCost = data;
    },
    deleteCost(state, data) {
      state.deleteCost = data;
    },
    editCostReturn(state, data) {
      state.editCostReturn = data;
    },
    updateTenantFee(state, data) {
      const tempArr = data.data.data;
      for (let i = 0; i < tempArr.length; i++) {
        const temp = {
          amount: tempArr[i].amount.toString(),
          name: tempArr[i].name,
          id: tempArr[i].id,
          invoice_id: tempArr[i].invoice_id,
          type: tempArr[i].type,
        };
        tempArr[i] = temp;
      }
      state.tenantFee = tempArr;
    },
    updateTenantFeeReturn(state, data) {
      state.returnTenantFee = data;
    },
    deleteTenantFee(state, data) {
      state.deleteTenantFee = data;
    },
    editTenantFeeReturn(state, data) {
      state.editTenantFeeReturn = data;
    },
  },
  actions: {
    async handleGetDisbursementDetail({ commit }, approval_id) {
      const response = await invoiceApi.getDisbursementDetail(approval_id);
      commit('updateInvoiceData', response.data);
      return response.data;
    },

    async getInvoiceData({ commit }, { property_id, month, year }) {
      const res = await invoiceApi.getContractOrderDetail({
        property_id,
        month,
        year,
      });
      commit('updateInvoiceData', res.data);
      return res.data;
    },
    async updateContract({ commit }, contract_id) {
      const res = await invoiceApi.updateContract({ contract_id });
      commit('updateContract', res);
      return res;
    },
    async approveContract({ commit }, approval_id) {
      const res = await invoiceApi.approveContract({ approval_id });
      commit('updateApprove', res);
      return res;
    },
    async getOperationalCost({ commit }, approval_id) {
      const res = await invoiceApi.getOperationalCost(approval_id);
      commit('updateCost', res);
      return res;
    },
    async postOperationalCost({ commit }, { approval_id, list_cost }) {
      const res = await invoiceApi.postOperationalCost({
        approval_id,
        list_cost,
      });
      commit('updateCostReturn', res);
      return res;
    },
    async deleteOperationalCost({ commit }, approval_id, cost_id) {
      const res = await invoiceApi.deleteOperationalCost(approval_id, cost_id);
      commit('deleteCost', res);
      return res;
    },
    async updateOperationalCost({ commit }, { approval_id, list_cost }) {
      const res = await invoiceApi.updateOperationalCost({
        approval_id,
        list_cost,
      });
      commit('editCostReturn', res);
      return res;
    },
    async getTenantFee({ commit }, { invoiceId }) {
      const res = await invoiceApi.getTenantFee(invoiceId);
      commit('updateTenantFee', res);
      return res;
    },
    async postTenantFee({ commit }, { invoiceId, additionalFee }) {
      const res = await invoiceApi.postTenantFee(invoiceId, {
        additional_fee: additionalFee,
      });
      commit('updateTenantFeeReturn', res);
      return res;
    },
    async deleteTenantFee({ commit }, { invoiceId, feeId }) {
      const res = await invoiceApi.deleteTenantFee(invoiceId, feeId);
      commit('deleteTenantFee', res);
      return res;
    },
    async updateTenantFee({ commit }, { invoiceId, listFee }) {
      const res = await invoiceApi.updateTenantFee({
        invoiceId,
        listFee,
      });
      commit('editTenantFeeReturn', res);
      return res;
    },
  },
};
