import dayjs from 'dayjs';

export default (startDate, endDate) => {
  if (!startDate || !endDate) return false;

  const startDateDayjs = dayjs(startDate);
  const endDateDayjs = dayjs(endDate);

  if (startDateDayjs.isValid() && endDateDayjs.isValid()) {
    return endDateDayjs.isAfter(startDate, 'day');
  }
  return false;
};
