export default {
  namespaced: true,

  state: {
    breadcrumbItems: [],
  },

  mutations: {
    setBreadcrumb(state, data) {
      if (data && Array.isArray(data)) {
        state.breadcrumbItems = [...data];
      } else {
        state.breadcrumbItems = [];
        console.warn('The BreadcrumbItems should be array');
      }
    },
    updateBreadcrumb(state, { index = null, item } = {}) {
      if (index !== null) {
        const breadcrumbs = [...state.breadcrumbItems];
        breadcrumbs[index] = { ...item };

        state.breadcrumbItems = breadcrumbs;
      }
    },
  },
};
