export default [
	{
		path: '/role-management',
		name: 'role-management',
		component: () =>
			import(
				/* webpackChunkName: "role management" */ '@admin/pages/RoleManagement'
			),
		meta: {
			auth: true,
		},
	},
	{
		path: '/role-management/roles/add',
		name: 'role-management-roles.add',
		component: () =>
			import(
				/* webpackChunkName: "role management - add role" */ '@admin/pages/RoleManagementRoles'
			),
		meta: {
			auth: true,
		},
	},
	{
		path: '/role-management/roles/:roleId/edit',
		name: 'role-management-roles.edit',
		component: () =>
			import(
				/* webpackChunkName: "role management - edit role" */ '@admin/pages/RoleManagementRoles'
			),
		meta: {
			auth: true,
		},
	},
	{
		path: '/role-management/members',
		name: 'role-management-members',
		component: () =>
			import(
				/* webpackChunkName: "role management - members" */ '@admin/pages/RoleManagementMembers'
			),
		meta: {
			auth: true,
		},
	},
	{
		path: '/role-management/:roleId/member-edit',
		name: 'role-management-member-edit',
		component: () =>
			import(
				/* webpackChunkName: "role management - edit member" */ '@admin/pages/RoleManagementMemberEdit'
			),
		meta: {
			auth: true,
		},
	}
];
