export default {
  state: {
    xSelectedProperty: {},
    xPropertyList: [],
  },
  getters: {
    xSelectedPropertyIsEmpty: state =>
      !Object.keys(state.xSelectedProperty).length,
    xSelectedPropertyId: state => state.xSelectedProperty?.val || null,
    xSelectedPropertyName: state => state.xSelectedProperty?.label || '',
    xPropertyListIsEmpty: state => !state.xPropertyList.length,
  },
  mutations: {
    xSetSelectedProperty(state, object) {
      state.xSelectedProperty = object || {};
    },
    xSetPropertyList(state, object) {
      state.xPropertyList = object;
    },
  },
};
