export const CONTRACT_STATUS = {
	PROBATION: '0',
	LIVE: '1',
	EXPIRED: '2',
};

export const selectedContractStatusDefault = [
	CONTRACT_STATUS.PROBATION,
	CONTRACT_STATUS.LIVE,
];

export default [
	{
		label: 'Probation',
		value: CONTRACT_STATUS.PROBATION,
	},
	{
		label: 'Live',
		value: CONTRACT_STATUS.LIVE,
	},
	{
		label: 'Expired',
		value: CONTRACT_STATUS.EXPIRED,
	},
];
