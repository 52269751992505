import { setToken, clearToken } from '@admin/api/service/cookiesToken';
import store from '@admin/store';
import { api } from '@admin/api/service';

const params = {
	client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
	client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
};

export default {
	issueToken({ email, password }) {
		return api
			.post('/singgahsini/api/auth/login', {
				email,
				password,
			})
			.then(res => {
				const {
					oauth: { access_token, refresh_token },
				} = res.data;
				setToken({ access_token, refresh_token });
				return res;
			});
	},
	logout() {
		return new Promise((resolve, reject) => {
			api
				.post('/singgahsini/api/auth/logout')
				.then(response => {
					clearToken();
					store.commit('resetAuth');
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	refreshToken(data) {
		return api.post('/oauth/token', {
			...params,
			...data,
			grant_type: 'refresh_token',
		});
	},
};
