export default [
  {
    path: '/disbursement',
    name: 'payment-disbursement-approval',
    component: () => import('@admin/pages/PaymentDisbursementApproval'),
    meta: {
      auth: true,
    },
  },

  {
    path: 'disbursement/detail-transfer/:approval_id',
    name: 'disbursement.detail-transfer',
    component: () => import('@admin/pages/DetailTransfer'),
    meta: {
      auth: true,
    },
  },

  {
    path: '/disbursement/add-manual-transaction/:approval_id',
    name: 'disbursement.add-manual-transaction',
    component: () => import('@admin/pages/FormManualTransaction'),
    meta: {
      auth: true,
    },
  },

  {
    path:
      '/disbursement/edit-mamikos-transaction/:approval_id/:disbursement_invoice_id',
    name: 'disbursement.edit-mamikos-transaction',
    component: () => import('@admin/pages/FormManualTransaction'),
    meta: {
      auth: true,
    },
  },

  {
    path:
      '/disbursement/edit-manual-transaction/:approval_id/:disbursement_invoice_id',
    name: 'disbursement.edit-manual-transaction',
    component: () => import('@admin/pages/FormManualTransaction'),
    meta: {
      auth: true,
    },
  },

  {
    path:
      'disbursement/additional-cost-detail/:approval_id/:disbursement_invoice_id',
    name: 'disbursement.additional-cost-detail',
    component: () => import('@admin/pages/AdditionalCostDetail'),
    meta: {
      auth: true,
    },
  },

  {
    path: 'disbursement/add-manual-transfer',
    name: 'disbursement.add-manual-transfer',
    component: () => import('@admin/pages/FormManualTransfer'),
    meta: {
      auth: true,
    },
  },
];
