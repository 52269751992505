export default [
  {
    path: '/billing-tracker',
    name: 'billing-tracker',
    component: () =>
      import(
        /* webpackChunkName: "billing-tracker" */ '@admin/pages/BillingTracker'
      ),
    meta: {
      auth: true,
    },
  },
];
