import PropertyDetailLayout from '@admin/components/layouts/PropertyDetailLayout';

export default [
	{
		path: '/property-detail/:propertyId',
		name: 'property-detail',
		component: PropertyDetailLayout,
		redirect: '/property-detail/:propertyId/overview',

		children: [
			{
				path: 'overview',
				name: 'property-detail.overview',
				component: () =>
					import(
						/* webpackChunkName: "property detail - overview" */ '@admin/pages/PropertyDetailOverview'
					),
				meta: {
					auth: true,
					dontUpdateParentComponent: true,
					breadcrumbs: [
						{
							name: 'Daftar Properti',
							url: '/',
						},
						{
							name: '...',
						},
					],
				},
			},
			{
				path: 'kos',
				name: 'property-detail.kos',
				component: () =>
					import(
						/* webpackChunkName: "property detail - kos detail" */ '@admin/pages/PropertyDetailKos'
					),
				meta: {
					auth: true,
					dontUpdateParentComponent: true,
					breadcrumbs: [
						{
							name: 'Daftar Properti',
							url: '/',
						},
						{
							name: '...',
						},
						{
							name: '...',
						}
					],
				},
			},
			{
				path: 'contract',
				name: 'property-detail.contract',
				component: () =>
					import(
						/* webpackChunkName: "property detail - contract" */ '@admin/pages/PropertyDetailContract'
					),
				meta: {
					auth: true,
					dontUpdateParentComponent: true,
					breadcrumbs: [
						{
							name: 'Daftar Properti',
							url: '/',
						},
						{
							name: '...',
						},
					],
				},
			},
			{
				path: 'performance',
				name: 'property-detail.performance',
				component: () =>
					import(
						/* webpackChunkName: "property detail - performance" */ '@admin/pages/PropertyDetailPerformance'
					),
				meta: {
					auth: true,
					dontUpdateParentComponent: true,
					breadcrumbs: [
						{
							name: 'Daftar Properti',
							url: '/',
						},
						{
							name: '...',
						},
					],
				},
			},
		],
	},
	{
		path: '/property-detail/:propertyId/contract-history',
		name: 'property-detail.contract.history',
		component: () =>
			import(
				/* webpackChunkName: "property detail - contract" */ '@admin/pages/ContractHistory'
			),
		meta: {
			auth: true,
			dontUpdateParentComponent: true,
			breadcrumbs: [
				{
					name: 'Daftar Properti',
					url: '/',
				},
				{
					name: '...',
				},
				{
					name: 'Riwayat Perubahan Kontrak',
				},
			],
		},
	},
	{
		path: '/property-detail/:propertyId/log',
		name: 'property-detail.changelog',
		component: () =>
			import(
				/* webpackChunkName: "property detail - contract" */ '@admin/pages/PropertyDetailChangelog'
			),
		meta: {
			auth: true,
			dontUpdateParentComponent: true,
			breadcrumbs: [
				{
					name: 'Daftar Properti',
					url: '/',
				},
				{
					name: '...',
				},
				{
					name: 'Riwayat Detail Kos',
				},
			],
		},
	},
];
