<template>
	<div class="mk-header-user">
		<div class="mk-header-user__dropdown-section">
			<bg-text size="title-5" class="mk-header-user__truncated">
				{{ userData.name }}
			</bg-text>
			<div class="mk-header-user__info">
				<bg-text
					size="label-4"
					class="mk-header-user__email mk-header-user__truncated"
				>
					{{ userData.email }}
				</bg-text>
			</div>
		</div>
		<bg-divider />
		<div
			class="mk-header-user__dropdown-section mk-header-user__logout"
			@click="logout"
		>
			<bg-icon name="logout" class="mk-header-user__logout-icon" />
			<bg-text size="label-3">Keluar</bg-text>
		</div>
	</div>
</template>

<script>
import { BgText, BgIcon, BgDivider } from 'bangul-vue';
import auth from '@admin/api/endpoints/auth';

export default {
	name: 'MkProfileDropdown',
	components: {
		BgText,
		BgIcon,
		BgDivider,
	},
	computed: {
		userData() {
			return this.$store.state.user.data;
		},
	},
	methods: {
		logout() {
			auth.logout().then(() => {
				this.$router.push({ name: 'login' });
			});
		},
	},
};
</script>

<style
	lang="scss"
	src="@admin/components/organisms/MkHeader/components/MkProfileDropdown.scss"
	scoped
></style>
