export default [
  {
    path: '/survey-tracker',
    name: 'survey-tracker',
    component: () =>
      import(
        /* webpackChunkName: "survey-tracker" */ '@admin/pages/SurveyTracker'
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: '/survey-tracker/profile/:surveyId',
    name: 'survey-tracker.profile',
    component: () =>
      import(
        /* webpackChunkName: "survey-tracker" */ '@admin/pages/SurveyTracker/pages/SurveyTrackerProfile'
      ),
    meta: {
      auth: true,
    },
  },
];
