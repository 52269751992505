<template>
  <aside class="sidebar">
    <div v-for="(items, index) in activeMenu" :key="index">
      <bg-tooltip
        :text="items.tooltip"
        :closable="false"
        variant="dark"
        placement="right"
        trigger="hover"
      >
        <bg-sidebar-item
          :icon-name="items.icon"
          label=""
          :active="isNavActive(items.navigation)"
          :data-testid="items.dataTestId"
          @click="handleClickNav(items.url)"
        />
      </bg-tooltip>
    </div>
  </aside>
</template>

<script>
import { BgSidebarItem, BgTooltip } from 'bangul-vue';

export default {
  name: 'MkSidebar',

  components: {
    BgSidebarItem,
    BgTooltip,
  },

  data() {
    return {
      sidebarItems: [
        {
          icon: 'home',
          tooltip: 'Daftar Properti',
          navigation: 'homepage',
          url: '/',
          show: true,
          dataTestId: 'homepageSidebarMenu',
        },
        {
          icon: 'chat',
          tooltip: 'Tenant Tracker',
          navigation: 'tenant-tracker',
          url: '/tenant-tracker',
          show: true,
          dataTestId: 'tenantCommunicationSidebarMenu',
        },
        {
          icon: 'total-room',
          tooltip: 'Survey Tracker',
          navigation: 'survey-tracker',
          url: '/survey-tracker',
          show: true,
          dataTestId: 'surveyTrackerSidebarMenu',
        },
        {
          icon: 'bill-management',
          tooltip: 'Billing Tracker',
          navigation: 'billing-tracker',
          url: '/billing-tracker',
          show: true,
          dataTestId: 'billingTrackerSideMenu',
        },
        {
          icon: 'saldo',
          tooltip: 'Transfer Pendapatan Pemilik',
          navigation: 'disbursement',
          url: '/disbursement',
          show: true,
          dataTestId: 'disbursementSidebarMenu',
        },
        {
          icon: 'term-condition',
          tooltip: 'Data Transaksi Lainnya',
          navigation: 'other-transaction',
          url: '/other-transaction/expenditure',
          show: true,
          dataTestId: 'otherTransactionSidebarMenu',
        },
        {
          icon: 'chart-kpi',
          tooltip: 'Evaluasi Properti',
          navigation: 'Evaluasi Properti',
          url: '/evaluasi-properti',
          show: false,
          dataTestId: '',
        },
        {
          icon: 'database-add',
          tooltip: 'Manajemen Biaya Tambahan',
          navigation: 'additional-fee-management',
          url: '/additional-fee-management',
          show: true,
          dataTestId: 'additionalFeeManagementSidebarMenu',
        },
        {
          icon: 'account',
          tooltip: 'Role Management',
          navigation: 'role',
          url: '/role-management',
          show: false,
          dataTestId: 'roleManagementSidebarMenu',
        },
      ],
    };
  },

  computed: {
    accessRoleManagement() {
      return this.$store.getters.xCheckUserPermission('access-role-management');
    },
    accessRoleSurvey() {
      return this.$store.getters.xCheckUserPermission('tenant-survey');
    },
    activePath() {
      const path = this.$route.path || '';

      return path;
    },
    activeMenu() {
      return this.sidebarItems.filter(item => item.show);
    },
  },

  watch: {
    accessRoleManagement: {
      immediate: true,
      handler(value) {
        this.sidebarItems[8].show = value;
      },
    },
    accessRoleSurvey: {
      immediate: true,
      handler(value) {
        this.sidebarItems[2].show = value;
      },
    },
  },

  methods: {
    handleClickNav(url) {
      if (this.activePath !== url) {
        this.$router.push(url);
      }
    },
    isNavActive(nav) {
      let activeRoute = '';

      if (
        this.activePath === '/' ||
        this.activePath.includes('property-detail') ||
        this.activePath.includes('room-allotment')
      ) {
        activeRoute = 'homepage';
      } else {
        activeRoute = this.activePath;
      }

      return activeRoute.includes(nav);
    },
  },
};
</script>

<style
  lang="scss"
  src="@admin/components/organisms/MkSidebar/MkSidebar.scss"
  scoped
></style>
