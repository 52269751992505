import propertyDetailApi from '@admin_endpoints/property-detail';

export const LOADING_STATE = {
  INITIAL: 'initial',
  FETCHING: 'fetching',
  ERROR_FETCHING: 'error_fetching',
  FETCH_DONE: 'fetch_done',
};

export default {
  namespaced: true,

  state: {
    xLoadingState: LOADING_STATE.INITIAL,
    xLoadingStateListing: LOADING_STATE.INITIAL,
    xPropertyDetail: {},
    xErrorMessage: '',
    xKosListingData: {},
    xLoadingStateListingFormOptions: LOADING_STATE.INITIAL,
    xKosListingFormOptions: {
      bed_types: [],
      window_locations: [],
      electricity_types: [],
    },
  },

  getters: {
    xPropertyDetailUnitType(state) {
      let temp = state.xPropertyDetail?.unit_type || [];

      if (temp && !Array.isArray(temp) && typeof temp === 'object') {
        temp = Object.entries(temp).map(([_, val]) => val);
      }

      const newArray = temp.filter(item => item !== null);
      return newArray.join(', ');
    },
    xPropertyDetailJobs(state) {
      const temp = state.xPropertyDetail.occupation_requirement || [];
      const newArray = temp.filter(item => item !== null);
      return newArray.join(', ');
    },
    xPropertyDetailReligions(state) {
      const temp = state.xPropertyDetail.religion_requirement || [];

      const newArray = temp.filter(item => item !== null);
      return newArray.join(', ');
    },
  },

  mutations: {
    setLoadingState(state, payload) {
      state.xLoadingState = payload;
    },
    setLoadingStateListing(state, payload) {
      state.xLoadingStateListing = payload;
    },
    setPropertyDetailData(state, payload) {
      state.xPropertyDetail = payload;
    },
    setErrorMessage(state, payload) {
      state.xErrorMessage = payload;
    },
    setKosListingData(state, payload) {
      state.xKosListingData = payload;
    },
    setLoadingStateListingFormOptions(state, payload) {
      state.xLoadingStateListingFormOptions = payload;
    },
    setListingFormOptions(state, payload) {
      state.xKosListingFormOptions = payload;
    },
  },

  actions: {
    async xConsumePropertyDetail({ commit }, propertyId) {
      commit('setLoadingState', LOADING_STATE.FETCHING);

      try {
        const result = await propertyDetailApi.getPropertyDetail(propertyId);
        const { data } = result.data;
        commit('setPropertyDetailData', data);
      } catch (error) {
        commit('setLoadingState', LOADING_STATE.ERROR_FETCHING);
        console.error(error);
      }

      commit('setLoadingState', LOADING_STATE.FETCH_DONE);
    },
    async xConsumeKosListing({ commit }, params) {
      commit('setLoadingStateListing', LOADING_STATE.FETCHING);
      const { propertyId, listingId } = params;

      try {
        const result = await propertyDetailApi.getKosListingId(
          propertyId,
          listingId
        );
        const { listing_detail } = result?.data || {};

        listing_detail.units = listing_detail.units.map(item => {
          const bedType = item.bed_type;
          const bedSize = item.bed_size && `${item.bed_size} cm`;

          return {
            ...item,
            roomBedInfo: [bedType, bedSize].join(
              bedSize && bedType ? ' - ' : ''
            ),
            isOpen: false,
          };
        });
        commit('setKosListingData', listing_detail);
      } catch (error) {
        commit('setLoadingStateListing', LOADING_STATE.ERROR_FETCHING);
        console.error(error);
      }

      commit('setLoadingStateListing', LOADING_STATE.FETCH_DONE);
    },
    async xConsumeListingFormOptions({ commit }, params) {
      commit('setLoadingStateListingFormOptions', LOADING_STATE.FETCHING);
      const { propertyId, listingId } = params;

      try {
        const response = await propertyDetailApi.getKostListingFormOptions(
          propertyId,
          listingId
        );

        const { bed_types, window_locations, electricity_types } =
          response?.data || {};

        commit('setListingFormOptions', {
          bed_types,
          window_locations,
          electricity_types,
        });
      } catch (error) {
        commit(
          'setLoadingStateListingFormOptions',
          LOADING_STATE.ERROR_FETCHING
        );
        console.error(error);
      }

      commit('setLoadingStateListingFormOptions', LOADING_STATE.FETCH_DONE);
    },
  },
};
