export default [
  {
    path: '/other-transaction',
    name: 'other-transaction',
    component: () => import('@admin/pages/OtherTransaction'),
    redirect: '/other-transaction/expenditure',
    children: [
      {
        path: 'expenditure',
        name: 'other-transaction.expenditure',
        component: () =>
          import('@admin/pages/OtherTransaction/OwnerExpenditure'),
        meta: { auth: true },
      },
      {
        path: 'deduction',
        name: 'other-transaction.deduction',
        component: () =>
          import('@admin/pages/OtherTransaction/EarningDeduction'),
        meta: { auth: true },
      },
    ],
  },
  {
    path: '/other-transaction/expenditure/add',
    name: 'other-transaction.expenditure.add',
    component: () =>
      import('@admin/pages/OtherTransaction/OwnerExpenditure/add'),
    meta: { auth: true },
  },
  {
    path: '/other-transaction/expenditure/edit/:property_id',
    name: 'other-transaction.expenditure.edit',
    component: () =>
      import('@admin/pages/OtherTransaction/OwnerExpenditure/edit'),
    meta: { auth: true },
  },
  {
    path: '/other-transaction/deduction/add/',
    name: 'other-transaction.deduction.add',
    component: () =>
      import('@admin/pages/OtherTransaction/EarningDeduction/AddDeduction'),
    meta: { auth: true },
  },
];