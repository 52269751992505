import api from '@admin/api/service';

const BASE_PATH = '/singgahsini/api/room-allotment';

export default {
  getPropertyList() {
    return api.get('/singgahsini/api/property-list');
  },

  getTableView(params) {
    return api.get(`${BASE_PATH}/tableview`, {
      params,
    });
  },

  getRoomAllotmentNote(invoiceId) {
    return api.get(`${BASE_PATH}/invoice/${invoiceId}/note`);
  },

  postRoomAllotmentNote(invoiceId, data) {
    return api.post(`${BASE_PATH}/invoice/${invoiceId}/note`, data);
  },

  getAvailableRoom(params) {
    return api.get(`${BASE_PATH}/calendarview/room-count`, {
      params,
    });
  },

  getCalendarView(params) {
    return api.get(`${BASE_PATH}/calendarview`, {
      params,
    });
  },

  postOutOfOrder(data) {
    return api.post(`${BASE_PATH}/out-of-order`, data);
  },

  updateOutOfOrder(id, data) {
    return api.put(`${BASE_PATH}/out-of-order/${id}`, data);
  },

  deactiveOutOfOrder(id) {
    return api.delete(`${BASE_PATH}/out-of-order/${id}`);
  },

  getContractHeaderInfo(contractId) {
    return api.get(`${BASE_PATH}/contract/${contractId}/info`);
  },

  getContractDetailList(contractId, query) {
    return api.get(`${BASE_PATH}/contract/${contractId}/invoice${query}`);
  },

  getPromoVoucherDetail(invoiceId) {
    return api.get(`${BASE_PATH}/invoice/${invoiceId}/voucher`);
  },

  getChangeLogList(roomId, params) {
    return api.get(`${BASE_PATH}/change-log/${roomId}`, {
      params,
    });
  },
};
