import api from '@admin/api/service';
import { makeRequest } from './index.js';

export const endpoints = {
  postAutoDisburseActivate: async contractId =>
    makeRequest(
      'POST',
      `/singgahsini/api/property/${contractId}/auto-disbursement/activate`
    ),
  postAutoDisburseDeactivate: async contractId =>
    makeRequest(
      'POST',
      `/singgahsini/api/property/${contractId}/auto-disbursement/deactivate`
    ),
  postPdfReportActivate: async contractId =>
    makeRequest(
      'POST',
      `/singgahsini/api/property/${contractId}/auto-disbursement-notification/activate`
    ),
  postPdfReportDeactivate: async contractId =>
    makeRequest(
      'POST',
      `/singgahsini/api/property/${contractId}/auto-disbursement-notification/deactivate`
    ),
};

export default {
  getPropertyDetail(propertyId) {
    return api.get(`/singgahsini/api/property/${propertyId}`);
  },

  getPropertyAllotment(propertyId) {
    return api.get(`/singgahsini/api/property/${propertyId}/singgahsini_info`);
  },

  getPropertyDisbursement(propertyId) {
    return api.get(
      `/singgahsini/api/property/${propertyId}/disbursement-contract`
    );
  },

  getKosRulesAndInfos(propertyId) {
    return api.get(`/singgahsini/api/property/${propertyId}/info`);
  },

  getKosListing(propertyId) {
    return api.get(`/singgahsini/api/property/${propertyId}/listing`);
  },

  getKosListingId(propertyId, id) {
    return api.get(`/singgahsini/api/property/${propertyId}/listing/${id}`);
  },

  getOwnerProfile(propertyId) {
    return api.get(`/singgahsini/api/property/${propertyId}/owner-profile`);
  },

  getDisbursementAndPartnership(propertyId) {
    return api.get(`/singgahsini/api/property/${propertyId}/disbursement-info`);
  },

  updatePropertyProfile(propertyId, data) {
    return api.put(`/singgahsini/api/property/${propertyId}`, data);
  },

  getPropertyLandmark(propertyId) {
    return api.get(`/singgahsini/api/property/${propertyId}/nearby-landmark`);
  },

  getFinancialHistory(propertyId) {
    return api.get(`/singgahsini/api/property/${propertyId}/financial-history`);
  },

  getPropertyChangelog(params) {
    const { propertyId, limit, offset } = params;

    return api.get(
      `singgahsini/api/property/${propertyId}/changelog?limit=${limit}&offset=${offset}`
    );
  },

  getPersonResponsible() {
    return api.get('/singgahsini/api/property/handler');
  },

  getKostListingFormOptions(propertyId, listingId) {
    return api.get(`/singgahsini/api/property/${propertyId}/listing/${listingId}/unit/prepare-form`)
  },

  activateAutoRenewal(contractId) {
    return api.put(
      `/singgahsini/api/contract/${contractId}/auto-renewal/activate`
    );
  },

  deactivateAutoRenewal(contractId) {
    return api.put(
      `/singgahsini/api/contract/${contractId}/auto-renewal/deactivate`
    );
  },
};
