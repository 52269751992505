import RoomAllotmentApi from '@admin_endpoints/room-allotment';

export const ModalState = {
	INITIAL: 'initial',
	FETCHING_NOTE: 'fetching_note',
	NOTE_FETCHED: 'note_fetched',
	SAVING_NOTE: 'saving_note',
	NOTE_SAVED: 'note_saved',
	ERROR_FETCHING: 'error_fetching',
	ERROR_SAVING: 'error_saving',
};

export default {
	namespaced: true,

	state: {
		modalState: ModalState.INITIAL,
		note: '',
		invoiceId: '',
	},

	getters: {
		isAnyChanges(state) {
			return note => note.trim() !== (state.note !== null ? state.note.trim() : '');
		},
	},

	mutations: {
		setModalState(state, payload) {
			state.modalState = payload;
		},
		setNote(state, payload) {
			state.note = payload;
		},
		setInvoiceId(state, payload) {
			state.invoiceId = payload;
		},
		resetState(state) {
			state.modalState = ModalState.INITIAL;
			state.note = '';
			state.invoiceId = '';
		},
	},

	actions: {
		async getCurrentNote({ commit }, invoiceId) {
			if (!invoiceId) return;

			commit('setModalState', ModalState.FETCHING_NOTE);
			commit('setInvoiceId', invoiceId);

			try {
				const result = await RoomAllotmentApi.getRoomAllotmentNote(invoiceId);

				commit('setNote', result.data.data?.content);
				commit('setModalState', ModalState.NOTE_FETCHED);
			} catch (error) {
				commit('setModalState', ModalState.ERROR_FETCHING);
			}
		},
		async saveNote({ state, commit }, note) {
			if (!state.invoiceId) return;

			commit('setModalState', ModalState.SAVING_NOTE);

			try {
				await RoomAllotmentApi.postRoomAllotmentNote(
					state.invoiceId,
					{ content: note }
				);
				commit('setModalState', ModalState.NOTE_SAVED);
			} catch (error) {
				commit('setModalState', ModalState.ERROR_SAVING);
			}
		},
	},
};
