import Vue from 'vue';
import VueRouter from 'vue-router';
import Cookies from 'js-cookie';
import AuthLayout from '@admin/components/layouts/AuthLayout';
import store from '@admin/store';
import { getAccessToken } from '@admin/api/service/cookiesToken';

import routePath from './paths';

Vue.use(VueRouter);
window.Cookies = Cookies;

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@admin/pages/auth/Login'),
  },
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        path: '/',
        name: 'homepage',
        component: () => import('@admin/pages/Homepage'),
        meta: {
          auth: true,
        },
      },
      ...routePath,
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const accessToken = getAccessToken();
  const isAuthPage = to.matched.some(record => record.meta.auth);

  if (accessToken) {
    store.commit('authenticate');
  } else {
    store.commit('resetAuth');
  }

  // Store breadcrumb to vuex
  store.commit('breadcrumb/setBreadcrumb', to.meta?.breadcrumbs || []);

  const { isLoggedIn } = store.state.auth;

  if (isAuthPage && !isLoggedIn) {
    next({
      name: 'login',
      query: { redirect: encodeURIComponent(to.fullPath) },
    });
  } else if (!isAuthPage && isLoggedIn) {
    next({ name: 'homepage' });
  } else {
    next();
  }
});

export default router;
