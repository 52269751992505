import RoomAllotmentApi from '@admin_endpoints/room-allotment';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import checkDateRange from '@/_admin/pages/RoomAllotmentCalendarView/components/CalendarView/utils/checkDateRange';
import { FORMAT_DATE } from '@/_admin/pages/RoomAllotmentCalendarView/components/CalendarView/utils';

dayjs.extend(isBetween);

// eslint-disable-next-line func-style
function transformData(data, oldData, isAppend) {
  const transformed = isAppend ? [...oldData] : [];
  const groupByKey = 'unit_type';
  // eslint-disable-next-line no-restricted-syntax
  for (const room of data) {
    const existingIndexRoom = transformed.findIndex(
      each => each[groupByKey] === room[groupByKey]
    );
    if (existingIndexRoom !== -1) {
      transformed[existingIndexRoom][groupByKey] = room[groupByKey];
      const { rooms } = transformed[existingIndexRoom];
      transformed[existingIndexRoom].rooms = [...rooms, room];
    } else {
      transformed.push({
        [groupByKey]: room[groupByKey],
        rooms: [room],
      });
    }
  }

  return transformed;
}

// eslint-disable-next-line func-style
function escapePolyfill() {
  return params => params;
}

// eslint-disable-next-line func-style
function escapeParams(params) {
  const escapeFn = escapePolyfill();
  const escapedParams = {};

  Object.keys(params).forEach(key => {
    escapedParams[key] = Array.isArray(params[key])
      ? params[key].map(val => escapeFn(val))
      : escapeFn(params[key]);
  });

  return escapedParams;
}

export const AsyncState = {
  INITIAL: 'initial',
  LOADING: 'loading',
  SUCCESS: 'success',
  NO_DATA: 'no_data',
  ERROR: 'error',
};

export default {
  namespaced: true,

  state: {
    asyncState: AsyncState.INITIAL,
    calendarViewData: [],
    days: [],
    availableRoomCache: {},
    infiniteScroll: {
      has_more: false,
      loading: false,
    },
    isFilterDateActive: false,
    isFromSubmitNewBooking: false,
  },

  getters: {
    getVisibleDateRange(state) {
      if (!state.days.length) return {};

      return {
        startDate: state.days[0].date,
        endDate: state.days[state.days.length - 1].date,
      };
    },
    activeDay(state) {
      const activeDay = state.days.find(day => day.isActive);

      return activeDay || {};
    },
    getAvailableRoomByDate(state) {
      return date => {
        const selectedDay = state.days.find(day => day.date === date);
        return selectedDay ? selectedDay.availableRoom : {};
      };
    },
    getAvailableRoomFromCache(state) {
      return date => state.availableRoomCache[date] || [];
    },
  },

  mutations: {
    setAsyncState(state, payload) {
      state.asyncState = payload;
    },
    setCalendarViewData(state, payload) {
      state.calendarViewData = [...payload];
    },
    setActiveDay(state, payload) {
      state.activeDay = { ...payload };
    },
    setAvailableRoom(state, payload) {
      state.days = state.days.map(day => {
        if (day.date === payload.date) {
          day.availableRoom.data = payload.data;
        }

        return day;
      });
    },
    setDays(state, payload) {
      state.days = [...payload];
    },
    updateActiveDay(state, date) {
      state.days = state.days.map(day => ({
        ...day,
        isActive: day.date === date,
      }));
    },
    updateAvailableRoomAsyncState(state, payload) {
      state.days = state.days.map(day => {
        if (day.date === payload.date) {
          day.availableRoom.asyncState = payload.asyncState;
        }

        return day;
      });
    },
    setAvailableRoomCache(state, payload) {
      state.availableRoomCache[payload.date] = payload.data;
    },
    setInfiniteScroll(state, payload) {
      state.infiniteScroll = {
        ...state.infiniteScroll,
        ...payload,
      };
    },
    resetState(state) {
      state.asyncState = AsyncState.INITIAL;
      state.calendarViewData = [];
      state.days = [];
      state.availableRoomCache = {};
      state.infiniteScroll = {
        has_more: false,
        loading: false,
      };
      state.isFilterDateActive = false;
    },
    setFilterDate(state, payload) {
      state.isFilterDateActive = payload;
    },
    resetAvailableRoomCache(state) {
      state.availableRoomCache = {};
    },
    resetAvailableRoomCounter(state) {
      state.days = state.days.map(day => ({
        ...day,
        availableRoom: {
          asyncState: AsyncState.INITIAL,
          data: [],
        },
      }));
    },
    setFromSubmitNewBookingState(state, payload) {
      state.isFromSubmitNewBooking = !!payload;
    },
  },

  actions: {
    async fetchCalendarViewData(
      { commit, getters, dispatch, state },
      { params, isInfiniteScroll = false }
    ) {
      if (isInfiniteScroll) commit('setInfiniteScroll', { loading: true });
      else commit('setAsyncState', AsyncState.LOADING);
      let error = false;

      try {
        const response = await RoomAllotmentApi.getCalendarView(
          escapeParams(params)
        );

        if (response.data.status) {
          if (response.data.data.length) {
            const data = transformData(
              response.data.data,
              state.calendarViewData,
              isInfiniteScroll
            );
            commit('setCalendarViewData', data);
            commit('setInfiniteScroll', response.data.pagination);

            if (!isInfiniteScroll) commit('setAsyncState', AsyncState.SUCCESS);

            if (getters.activeDay.date) {
              await dispatch('fetchTotalAvailableRoom', {
                property_id: params.property_id,
                date: getters.activeDay.date,
              });
            }
          } else if (!isInfiniteScroll)
            commit('setAsyncState', AsyncState.NO_DATA);
        } else if (!isInfiniteScroll)
          commit('setAsyncState', AsyncState.NO_DATA);
      } catch (err) {
        if (!isInfiniteScroll) commit('setAsyncState', AsyncState.NO_DATA);
        error = err;
      } finally {
        commit('setInfiniteScroll', { loading: false });
      }

      return error ? Promise.reject(error) : Promise.resolve(true);
    },

    async fetchTotalAvailableRoom({ commit, getters }, queryParams) {
      const availableRoom = getters.getAvailableRoomByDate(queryParams.date);

      if (availableRoom.asyncState !== AsyncState.SUCCESS) {
        commit('updateAvailableRoomAsyncState', {
          date: queryParams.date,
          asyncState: AsyncState.LOADING,
        });
        try {
          const response = await RoomAllotmentApi.getAvailableRoom(
            escapeParams(queryParams)
          );
          if (response.data.status) {
            commit('setAvailableRoom', {
              date: queryParams.date,
              data: response.data.data,
            });
            commit('updateAvailableRoomAsyncState', {
              date: queryParams.date,
              asyncState: AsyncState.SUCCESS,
            });
            commit('setAvailableRoomCache', {
              date: queryParams.date,
              data: response.data.data,
            });
          } else {
            commit('updateAvailableRoomAsyncState', {
              date: queryParams.date,
              asyncState: AsyncState.ERROR,
            });
          }
        } catch (error) {
          commit('updateAvailableRoomAsyncState', {
            date: queryParams.date,
            asyncState: AsyncState.ERROR,
          });
        }
      }
    },

    setVisibleDates({ commit, getters }, { startDate, endDate }) {
      if (checkDateRange(startDate, endDate)) {
        const days = [];
        let startDateCopy = startDate;

        while (startDateCopy.isSameOrBefore(endDate)) {
          const existingAvailableRoom = getters.getAvailableRoomFromCache(
            startDateCopy.format(FORMAT_DATE)
          );
          const dayData = {
            date: startDateCopy.format(FORMAT_DATE),
            dateNumber: startDateCopy.date(),
            day: startDateCopy.format('ddd'),
            isWeekend: [0, 6].includes(+startDateCopy.format('d')),
            isToday: startDateCopy.isToday(),
            isActive: false,
            availableRoom: {
              asyncState: AsyncState.INITIAL,
              data: [],
            },
          };

          if (existingAvailableRoom.length) {
            dayData.availableRoom = {
              asyncState: AsyncState.SUCCESS,
              data: [...existingAvailableRoom],
            };
          }

          days.push(dayData);
          startDateCopy = startDateCopy.add(1, 'day');
        }

        commit('setDays', days);
      }
    },
  },
};
