import roleManagement from './roleManagement';
import roomAllotment from './roomAllotment';
import propertyDetail from './propertyDetail';
import propertyAdditionalFee from './propertyAdditionalFee';
import disbursement from './disbursement';
import tenantTracker from './tenantTracker';
import surveyTracker from './surveyTracker';
import otherTransaction from './otherTransaction';
import billingTracker from './billingTracker';
import additionalFeeManagement from './additionalFeeManagement';

const routePath = [
  ...roleManagement,
  ...roomAllotment,
  ...propertyDetail,
  ...propertyAdditionalFee,
  ...disbursement,
  ...tenantTracker,
  ...surveyTracker,
  ...otherTransaction,
  ...billingTracker,
  ...additionalFeeManagement
];

export default routePath;
