import api from '@admin/api/service';
import { makeRequest } from './index.js';

export const endpoints = {
  deleteAdditionalIncome: async (approvalId, additionalIncomeId) =>
    makeRequest(
      'DELETE',
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/additional-income/${additionalIncomeId}`
    ),
  deleteOperationalCost: async (approvalId, costId) =>
    makeRequest(
      'DELETE',
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/cost/${costId}`
    ),
  getAdditionalIncome: async approvalId =>
    makeRequest(
      'GET',
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/additional-income`
    ),
  getAdditionalTenantCost: async disbursementId =>
    makeRequest(
      'GET',
      `singgahsini/api/income/approval-property-disbursement/${disbursementId}/additional-cost`
    ),
  getBigflipImage: async approvalId =>
    makeRequest(
      'GET',
      `singgahsini/api/income/approval-property-disbursement/${approvalId}/bigflip-receipt-link`
    ),
  getDisbursementAmountProperties: async approvalId =>
    makeRequest(
      'GET',
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/owner-net-income/`
    ),
  postAdditionalIncome: async (approvalId, params) =>
    makeRequest(
      'POST',
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/additional-income`,
      params
    ),
  putAdditionalIncome: async (approvalId, additionalIncomeId, params) =>
    makeRequest(
      'PUT',
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/additional-income/${additionalIncomeId}`,
      params
    ),
  getDisbursedOta: async disbursementId =>
    makeRequest(
      'GET',
      `/singgahsini/api/income/approval-property-disbursement/${disbursementId}/ota`
    ),
};

export default {
  approveContract({ approval_id }) {
    return api
      .post(
        `/singgahsini/api/income/approval-property-disbursement/${approval_id}/approve`
      )
      .then(res => res);
  },
  updateContract({ contract_id }) {
    return api
      .post('/singgahsini/api/income/update', {
        contract_id,
      })
      .then(res => res);
  },
  getInvoiceList(approvalId) {
    return api.get(
      `singgahsini/api/income/approval-property-disbursement/${approvalId}/invoice`
    );
  },

  deleteInvoice(approvalId, invoiceId) {
    return api.delete(
      `singgahsini/api/income/approval-property-disbursement/${approvalId}/invoice/${invoiceId}`
    );
  },

  getDisbursementDetail(approval_id) {
    return api.get(
      `singgahsini/api/income/approval-property-disbursement/${approval_id}`
    );
  },

  getOperationalCost({ approval_id: id }) {
    return api.get(
      `/singgahsini/api/income/approval-property-disbursement/${id}/cost`
    );
  },

  postOperationalCost({ approval_id, list_cost }) {
    return api.post(
      `/singgahsini/api/income/approval-property-disbursement/${approval_id}/cost`,
      list_cost
    );
  },
  updateOperationalCost({ approval_id, cost_id, list_cost }) {
    return api
      .put(
        `/singgahsini/api/income/approval-property-disbursement/${approval_id}/cost/${cost_id}`,
        list_cost
      )
      .then(res => res);
  },
  getTenantFee(invoiceId) {
    return api
      .get(
        `/singgahsini/api/income/approval-detail/${invoiceId}/additional-fee`
      )
      .then(res => res);
  },
  postTenantFee(invoiceId, additionalFee) {
    return api
      .post(
        `/singgahsini/api/income/approval-detail/${invoiceId}/additional-fee`,
        additionalFee
      )
      .then(res => res);
  },
  deleteTenantFee(invoiceId, feeId) {
    return api
      .delete(
        `/singgahsini/api/income/approval-detail/${invoiceId}/additional-fee/${feeId}`
      )
      .then(res => res);
  },

  getAdditionalCost(approvalId, invoiceId) {
    return api.get(
      `singgahsini/api/income/approval-property-disbursement/${approvalId}/invoice/${invoiceId}/additional-cost`
    );
  },

  storeAdditionalCost(approvalId, invoiceId, data) {
    return api.post(
      `singgahsini/api/income/approval-property-disbursement/${approvalId}/invoice/${invoiceId}/additional-cost`,
      data
    );
  },

  updateAdditionalCost(approvalId, invoiceId, costId, data) {
    return api.put(
      `singgahsini/api/income/approval-property-disbursement/${approvalId}/invoice/${invoiceId}/additional-cost/${costId}`,
      data
    );
  },

  deleteAdditionalCost(approvalId, invoiceId, costId) {
    return api.delete(
      `singgahsini/api/income/approval-property-disbursement/${approvalId}/invoice/${invoiceId}/additional-cost/${costId}`
    );
  },

  deleteInvoiceManualAdditionalCost(approvalId, costId) {
    return api.delete(
      `singgahsini/api/income/approval-property-disbursement/${approvalId}/mamipay-manual-invoice/${costId}`
    );
  },

  updateAdditionalNotes(approvalId, data) {
    return api.post(
      `singgahsini/api/income/approval-property-disbursement/${approvalId}/notes`,
      data
    );
  },
  pushNotification(approvalId) {
    return api.post(
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/push-notif/zero-disbursement`
    );
  },
  recalculate(approvalId) {
    return api.patch(
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/owner-net-income`
    );
  },
};
