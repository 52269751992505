import Vue from 'vue';
import Vuex from 'vuex';

import toast from 'Plugins/global-toast/toastStoreModule';
import auth from './auth';
import user from './user';
import roomAllotment from './roomAllotment';
import calendarView from './modules/calendarView';
import note from './modules/note';
import additionalCost from './modules/additionalCost';
import homepage from './modules/homepage';
import propertyDetail from './modules/propertyDetail';
import detailTransfer from './detail-transfer';
import breadcrumb from './modules/breadcrumb';

Vue.use(Vuex);

export const storeModules = {
  modules: {
    auth,
    user,
    calendarView,
    note,
    additionalCost,
    homepage,
    propertyDetail,
    toast,
    roomAllotment,
    detailTransfer,
    breadcrumb,
  },
};

export default new Vuex.Store(storeModules);
