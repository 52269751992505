import VueRouter from 'vue-router';
import axios from 'axios';
import {
  getAccessToken,
  clearToken,
  setToken,
  getRefreshToken,
} from '@admin/api/service/cookiesToken.js';
import { api } from '@admin/api/service';
import auth from '@admin/api/endpoints/auth.js';
import router from '@admin/router/index.js';

const { isNavigationFailure, NavigationFailureType } = VueRouter;

const interceptors = () => {
  api.interceptors.request.use(
    config => {
      const token = getAccessToken();
      if (token) {
        config.headers.common.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => Promise.reject(error)
  );

  api.interceptors.response.use(
    response => {
      if (response.status >= 200 && response.status <= 300) {
        return Promise.resolve(response);
      }
      return Promise.reject(response);
    },
    error => {
      const originalRequest = error.config;

      if (error?.response?.status === 401) {
        // When access_token can't be used anymore(expired), refresh that token
        const refreshToken = getRefreshToken();
        return auth
          .refreshToken({
            refresh_token: refreshToken,
          })
          .then(res => {
            const { access_token, refresh_token } = res.data;

            if (res.status === 200) {
              setToken({ access_token, refresh_token });
              originalRequest.headers.Authorization = `Bearer ${getAccessToken()}`;
              return axios(originalRequest);
            }
          })
          .catch(err => {
            // When failed on refreshing token, redirect to login page
            clearToken();
            router.push({ name: 'login' }).catch(failure => {
              if (
                !isNavigationFailure(failure, NavigationFailureType.duplicated)
              ) {
                throw new Error(failure);
              }
            });
            return Promise.reject(err);
          });
      }

      return Promise.reject(error);
    }
  );
};

export default interceptors;
