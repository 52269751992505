export default [
  {
    path: '/additional-fee-management',
    name: 'additional-fee-management',
    component: () =>
      import(
        /* webpackChunkName: "additional-fee-management" */ '@admin/pages/AdditionalFeeManagement'
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: '/additional-fee-management/add',
    name: 'additional-fee-management.add',
    component: () =>
      import(/* webpackChunkName: "add-additional-fee" */ '@admin/pages/AdditionalFeeManagement/add'),
    meta: { auth: true },
  },
];
