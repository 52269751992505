import api from '@admin/api/service';

export default {
  getProfile() {
    return api.get('/singgahsini/api/user/profile');
  },
  getUsers(params, cancelToken = null) {
    return api.get('/singgahsini/api/user', {
      params,
      cancelToken,
    });
  },
  uploadIdentityCard(payload, options = {}) {
    return api.post(
      '/singgahsini/api/user/verification/identity-card/upload',
      payload,
      options,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
  getUserBookingDraftPrice(listingId) {
    return api.get(`/singgahsini/api/user/booking/draft-price/${listingId}`);
  },
};
