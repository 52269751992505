import store from '@admin/store';
import { api } from '../service';

// eslint-disable-next-line no-unused-vars
const makeRequest = async (
  method,
  url,
  params = null,
  data = null,
  showErrorToast = true,
  headerContentType = 'application/json',
) => {
  try {
    const response = await api({
      method,
      url,
      params,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': headerContentType,
      },
    });

    return response.data;
  } catch (error) {
    if (showErrorToast) {
      store.dispatch('toast/showToast', {
        message:
          error || "Error: There's something wrong, please contact the admin",
        options: {
          variant: 'error',
        },
      });
    }

    if (error?.response?.status === 200 || error?.status === 200) {
      return error;
    }

    if (error?.response?.status === 400 || error?.response?.status === 422) {
      return error.response;
    }

    return false;
  }
};

export { makeRequest };
