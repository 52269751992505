import Cookies from 'js-cookie';

export const options = {
  sameSite: 'strict',
  domain: process.env.VUE_APP_OAUTH2_DOMAIN_ADMIN,
};

export function setToken(token) {
  Cookies.set('access_admin_token', token.access_token, options);
  Cookies.set('refresh_admin_token', token.refresh_token, options);
}

export function getAccessToken() {
  return Cookies.get('access_admin_token');
}

export function getRefreshToken() {
  return Cookies.get('refresh_admin_token');
}

export function clearToken() {
  Cookies.remove('access_admin_token', options);
  Cookies.remove('refresh_admin_token', options);
}
